/*
Import all base styles for sass compilation to be linked to in templates/base.html
*/

// tailwind
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// config
@import "./config/base", "./config/colors", "./config/extensions", "./config/layout";
// app
@import "app/Directory/labels", "app/Directory/search", "app/Directory/selection-object-styles",
	"app/Directory/structure", "app/feedback", "app/forms", "app/modals", "app/notifications", "app/page-curtain",
	"app/slideout-portal", "app/task-watcher";
// pages
@import "pages/Admin/base", "pages/Admin/employees", "pages/Company/Directory/structure",
	"pages/Company/ReportingWizard/query-builder", "pages/Company/ReportingWizard/reporting-controls",
	"pages/Company/ReportingWizard/results-container", "pages/Company/ReportingWizard/save-container",
	"pages/Company/ReportingWizard/structure", "pages/Company/ReportingWizard/table-editor",
	"pages/feedback/viewcontainer", "pages/tests/draft-plugin", "pages/create-review-cycle", "pages/group-view-page",
	"pages/slideout-feedback", "pages/view-feedback", "pages/view-group", "pages/view-review-cycle";
// inputs
@import "inputs/toggleinput",
	// slideouts,
	"slideouts/feedback",
	"slideouts/main";
// components
@import "components/inputs/checkbox", "components/inputs/datepicker", "components/inputs/general",
	"components/inputs/single-input", "components/inputs/toggle-switches", "components/visuals/donut",
	"components/avatar", "components/chip", "components/drop-down-menu", "components/filter-slideout",
	"components/hashtag-plugin", "components/icon", "components/modal", "components/progress-timeline",
	"components/spinner", "components/table", "components/view-feedback";

:root {
	// primary
	--py-primary-1: #{map-get($colorsMap, "primary", 1)};
	--py-primary-2: #{map-get($colorsMap, "primary", 2)};
	--py-primary-3: #{map-get($colorsMap, "primary", 3)};
	--py-primary-4: #{map-get($colorsMap, "primary", 4)};
	--py-primary-5: #{map-get($colorsMap, "primary", 5)};
	--py-primary-6: #{map-get($colorsMap, "primary", 6)};
	--py-primary-7: #{map-get($colorsMap, "primary", 7)};
	--py-primary-8: #{map-get($colorsMap, "primary", 8)};
	--py-primary-9: #{map-get($colorsMap, "primary", 9)};

	// green
	--py-green-1: #{map-get($colorsMap, "green", 1)};
	--py-green-2: #{map-get($colorsMap, "green", 2)};
	--py-green-3: #{map-get($colorsMap, "green", 3)};
	--py-green-4: #{map-get($colorsMap, "green", 4)};
	--py-green-5: #{map-get($colorsMap, "green", 5)};
	--py-green-6: #{map-get($colorsMap, "green", 6)};
	--py-green-7: #{map-get($colorsMap, "green", 7)};
	--py-green-8: #{map-get($colorsMap, "green", 8)};
	--py-green-9: #{map-get($colorsMap, "green", 9)};

	// red
	--py-red-1: #{map-get($colorsMap, "red", 1)};
	--py-red-2: #{map-get($colorsMap, "red", 2)};
	--py-red-3: #{map-get($colorsMap, "red", 3)};
	--py-red-4: #{map-get($colorsMap, "red", 4)};
	--py-red-5: #{map-get($colorsMap, "red", 5)};
	--py-red-6: #{map-get($colorsMap, "red", 6)};
	--py-red-7: #{map-get($colorsMap, "red", 7)};
	--py-red-8: #{map-get($colorsMap, "red", 8)};
	--py-red-9: #{map-get($colorsMap, "red", 9)};

	// orange
	--py-orange-1: #{map-get($colorsMap, "orange", 1)};
	--py-orange-2: #{map-get($colorsMap, "orange", 2)};
	--py-orange-3: #{map-get($colorsMap, "orange", 3)};
	--py-orange-4: #{map-get($colorsMap, "orange", 4)};
	--py-orange-5: #{map-get($colorsMap, "orange", 5)};
	--py-orange-6: #{map-get($colorsMap, "orange", 6)};
	--py-orange-7: #{map-get($colorsMap, "orange", 7)};
	--py-orange-8: #{map-get($colorsMap, "orange", 8)};
	--py-orange-9: #{map-get($colorsMap, "orange", 9)};

	// yellow
	--py-yellow-1: #{map-get($colorsMap, "yellow", 1)};
	--py-yellow-2: #{map-get($colorsMap, "yellow", 2)};
	--py-yellow-3: #{map-get($colorsMap, "yellow", 3)};
	--py-yellow-4: #{map-get($colorsMap, "yellow", 4)};
	--py-yellow-5: #{map-get($colorsMap, "yellow", 5)};
	--py-yellow-6: #{map-get($colorsMap, "yellow", 6)};
	--py-yellow-7: #{map-get($colorsMap, "yellow", 7)};
	--py-yellow-8: #{map-get($colorsMap, "yellow", 8)};
	--py-yellow-9: #{map-get($colorsMap, "yellow", 9)};

	// pale yellow
	--py-pale-yellow-1: #{map-get($colorsMap, "pale-yellow", 1)};
	--py-pale-yellow-2: #{map-get($colorsMap, "pale-yellow", 2)};
	--py-pale-yellow-3: #{map-get($colorsMap, "pale-yellow", 3)};
	--py-pale-yellow-4: #{map-get($colorsMap, "pale-yellow", 4)};
	--py-pale-yellow-5: #{map-get($colorsMap, "pale-yellow", 5)};
	--py-pale-yellow-6: #{map-get($colorsMap, "pale-yellow", 6)};
	--py-pale-yellow-7: #{map-get($colorsMap, "pale-yellow", 7)};
	--py-pale-yellow-8: #{map-get($colorsMap, "pale-yellow", 8)};
	--py-pale-yellow-9: #{map-get($colorsMap, "pale-yellow", 9)};

	// neutral
	--py-neutral-0: #{map-get($colorsMap, "neutral", 0)};
	--py-neutral-1: #{map-get($colorsMap, "neutral", 1)};
	--py-neutral-2: #{map-get($colorsMap, "neutral", 2)};
	--py-neutral-3: #{map-get($colorsMap, "neutral", 3)};
	--py-neutral-4: #{map-get($colorsMap, "neutral", 4)};
	--py-neutral-5: #{map-get($colorsMap, "neutral", 5)};
	--py-neutral-6: #{map-get($colorsMap, "neutral", 6)};
	--py-neutral-7: #{map-get($colorsMap, "neutral", 7)};
	--py-neutral-8: #{map-get($colorsMap, "neutral", 8)};
	--py-neutral-9: #{map-get($colorsMap, "neutral", 9)};
	--py-neutral-10: #{map-get($colorsMap, "neutral", 10)};
}
