/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.component-notifications-container {
	position: fixed;
	width: 300px;
	z-index: 100;
}
// .component-notifications-container { right: 70px; bottom: 20px; }
.component-notifications-container {
	left: 20px;
	bottom: 20px;
}

/*--------------------------------------------------------------
3.0 - Notification Box
--------------------------------------------------------------*/
.component-notifications-container {
	.notification-box {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		border-radius: 4px;
		animation-duration: 500ms;
		animation-fill-mode: both;
		animation-name: bounceInUp;
	}

	.notification-box .close-notification {
		cursor: pointer;
		position: absolute;
		top: -12px;
		right: -12px;
		width: 18px;
		height: 18px;
		color: $color-red;
		background-color: var(--py-neutral-0);
		border: 2px solid $color-red;
		@extend .circle;
		box-shadow: 0 0 0 2px var(--py-neutral-0);

		.cp-icon {
			position: absolute;
			top: 2px;
			left: 2px;
			text-align: center;
			font-size: 14px;
		}

		&:hover {
			background-color: $color-red;
			.cp-icon {
				color: var(--py-neutral-0);
			}
		}
	}

	.notification-box .inner-wrapper {
		padding: 10px 10px;
	}
	.notification-box .message-icon {
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 20px;
	}
	.notification-box .message-icon {
		top: 50%;
		margin-top: -10px;
	}

	.notification-box .message {
		margin-left: 30px;
		line-height: 20px;
		font-size: 13px;
	}

	.notification-box.no-message {
		width: auto;
		padding: 0 20px;

		.inner-wrapper {
			padding: 20px 10px;
			width: 18px;
		}
	}
}

/*--------------------------------------------------------------
4.0 - Success Notification
--------------------------------------------------------------*/
.component-notifications-container {
	.notification-box.type-success {
		background-color: $color-green;

		.message {
			color: var(--py-neutral-0);
		}
		.message-icon {
			color: var(--py-neutral-0);
		}
	}
}

/*--------------------------------------------------------------
4.0 - Error Notification
--------------------------------------------------------------*/
.component-notifications-container {
	.notification-box.type-error {
		background-color: var(--py-red-5);

		.message {
			color: var(--py-neutral-0);
		}
		.message-icon {
			color: var(--py-neutral-0);
		}
	}
}

/*--------------------------------------------------------------
5.0 - Error Notification
--------------------------------------------------------------*/
.component-notifications-container {
	.notification-box.type-warning {
		background-color: $color-orange;

		.message {
			color: var(--py-neutral-0);
		}
		.message-icon {
			color: var(--py-neutral-0);
		}
	}
}

/*--------------------------------------------------------------
5.0 - Undo Notification
--------------------------------------------------------------*/
.component-notifications-container {
	.notification-box.type-undo {
		padding: 4px 0;
		background-color: var(--py-neutral-8);

		.message {
			margin-right: 70px;
			color: var(--py-neutral-0);
		}
		.message-icon {
			color: var(--py-neutral-0);
		}

		.button-container {
			position: absolute;
			top: 0;
			right: 0;
			width: 70px;
			height: 100%;

			.undo-button {
				position: absolute;
				cursor: pointer;
				top: 50%;
				margin-top: -15px;
				right: 10px;
			}
			.undo-button {
				width: 60px;
				height: 30px;
				border-radius: 3px;
				background-color: $color-blue;
				color: var(--py-neutral-0);
			}
			.undo-button {
				text-align: center;
				line-height: 30px;
				font-size: 12px;
				text-transform: uppercase;
			}

			.undo-button:hover {
				background-color: lighten($color-blue, 10%);
			}
		}
	}
}

/*--------------------------------------------------------------
4.0 - Toast Notification
--------------------------------------------------------------*/
.component-notifications-container {
	.notification-box.type-toast {
		background-color: var(--py-neutral-8);

		.inner-wrapper {
			padding: 15px 20px;
		}
		.message {
			margin-left: 0;
			margin-right: 75px;
			color: var(--py-neutral-0);
			font-size: 14px;
		}

		.action {
			cursor: pointer;
			position: absolute;
			top: -3px;
			right: -5px;
			height: 26px;
			width: 70px;
			background-color: $color-blue;
			color: var(--py-neutral-0);
			border-radius: 2px;
			text-align: center;
			line-height: 26px;
			font-size: 13px;
			font-weight: 600;

			&:hover {
				background-color: lighten($color-blue, 5%);
			}
		}
	}
}

/*--------------------------------------------------------------
5.0 - Bounce In Animation
--------------------------------------------------------------*/
@keyframes bounceInUp {
	from,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 3000px, 0);
		transform: translate3d(0, 3000px, 0);
	}

	60% {
		opacity: 1;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}

	75% {
		-webkit-transform: translate3d(0, 10px, 0);
		transform: translate3d(0, 10px, 0);
	}

	90% {
		-webkit-transform: translate3d(0, -5px, 0);
		transform: translate3d(0, -5px, 0);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
