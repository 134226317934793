/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
$padding: 20px;

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.companyreporting-page {
	.table-editor-container {
	}
}

/*--------------------------------------------------------------
3.0 - Form
--------------------------------------------------------------*/
.companyreporting-page {
	.table-editor-container {
		.form-container {
			margin-top: 10px;
			.input-label {
				margin-bottom: 4px;
				font-size: 14px;
				font-weight: 400;
				color: var(--py-neutral-8);
			}
		}
	}
}

/*--------------------------------------------------------------
4.0 - Col List
--------------------------------------------------------------*/
.companyreporting-page {
	.table-editor-container {
		.table-columns-list {
			margin-top: 10px;
			padding-top: 10px;
			border-top: 1px solid var(--py-neutral-1);

			.column-item {
				margin-top: 5px;
				padding: 10px 10px;
				background-color: lighten($color-blue, 45%);
				border-radius: 3px;
				cursor: grab;

				.label {
					font-size: 14px;
					line-height: 20px;
					font-weight: 600;
					color: inherit;
				}
				.rm-box {
					position: absolute;
					cursor: pointer;
					top: 8px;
					right: 10px;
					width: 24px;
					height: 24px;
					line-height: 24px;
					text-align: center;
					color: $color-blue;
					display: none;
					@extend .circle;
				}
				&:hover .rm-box {
					display: block;
				}
				&:hover .rm-box:hover {
					background-color: var(--py-neutral-0);
					color: $color-red;
				}
			}
		}
	}
}

/*--------------------------------------------------------------
5.0 - Controls
--------------------------------------------------------------*/
.companyreporting-page {
	.table-editor-container {
		.controls {
			margin-top: 10px;
			padding-top: 10px;
			border-top: 1px solid var(--py-neutral-1);

			.control {
				position: absolute;
				top: 0;
			}
			.apply-button {
				right: 0;
			}
		}
	}
}
