/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
$bludeShade: lighten($color-blue, 46%);

/*--------------------------------------------------------------
2.0 - Mention Classes
--------------------------------------------------------------*/
.cp-visual-donut {
	.donutchart-track {
		fill: transparent;
		stroke-width: 26;
	}
	.donutchart-indicator {
		fill: transparent;
		stroke-width: 26;
		stroke-dasharray: 0 10000;
		transition: stroke-dasharray 0.4s ease;
	}
	.donutchart {
		margin: 0 auto;
		border-radius: 50%;
		display: block;
	}
	.donutchart-text {
	}
	.donutchart-text-val {
		font-size: 36px;
		font-weight: 800;
	}
	.donutchart-text-percent {
		padding-left: 3px;
		font-size: 36px;
		font-weight: 300;
	}
	.donutchart-text-label {
		font-size: 13px;
	}
}
