/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.cp-avatar {
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		@extend .circle;
	}
}
