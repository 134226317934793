/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.cp-directory {
	.search-container {
		.search-input-container {
			position: relative;
		}
		.search-label {
			margin-bottom: 5px;
			line-height: 15px;
			font-size: 14px;
			font-weight: 600;
			color: var(--py-neutral-8);
		}

		.download-container {
			position: absolute;
			top: 0;
			left: 360px;
			line-height: 28px;
			font-size: 12px;
			font-weight: 600;
		}

		.download-csv-button {
			text-align: center;
		}

		.change-container {
			position: absolute;
			top: 10px;
			right: 6px;
			line-height: 20px;
			font-size: 14px;
			font-weight: 600;
		}
	}
}
