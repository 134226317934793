/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.cp-single-select {
	.select-option {
		margin-top: 5px;
		padding: 0 5px;
		.inner-wrapper {
			display: inline-block;
			cursor: pointer;
		}
		&:first-child {
			margin-top: 0;
		}
		.select-box {
			position: absolute;
			top: 0;
			left: 0;
			width: 20px;
			height: 30px;
			text-align: center;
			color: var(--py-neutral-2);
			line-height: 30px;
			font-size: 14px;
		}
		.option-label {
			padding: 5px 0;
			margin-left: 22px;
			font-size: 14px;
			line-height: 22px;
			min-height: 22px;
			font-weight: 400;
			color: var(--py-neutral-8);
		}

		&:hover {
			.select-box {
				color: var(--py-neutral-3);
			}
			.option-label {
				color: $color-blue;
			}
		}

		&.is-active {
			background-color: var(--py-neutral-1);
			.select-box {
				color: $color-green;
			}
			.option-label {
				color: var(--py-neutral-8);
			}
		}
	}
}

.cp-multi-select {
	.select-option {
		margin-top: 10px;
		.inner-wrapper {
			display: inline-block;
			cursor: pointer;
		}
		&:first-child {
			margin-top: 0;
		}
		.select-box {
			float: left;
		}
		.option-label {
			font-size: 14px;
			font-weight: 600;
			color: var(--py-neutral-8);
		}
	}
}
