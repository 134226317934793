/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
$blueShade: lighten($color-blue, 45%);

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.cp-directory {
	.letter-labels-container {
		position: absolute;
		left: 0;
		top: 0;

		.toggle-letter-button-container {
			.toggle-letter-button {
				cursor: pointer;
				box-sizing: border-box;
				text-align: center;
				font-size: 14px;
				font-weight: 600;
				background-color: $blueShade;
				color: $color-blue;
				border-radius: 2px;
				border: 1px solid lighten($color-blue, 30%);
				min-height: 18px;
				&:hover {
					background-color: $color-blue;
					color: var(--py-neutral-0);
					border: 1px solid darken($color-blue, 10%);
				}
			}

			.toggle-tooltip {
				position: absolute;
				display: none;
				padding: 0 10px;
				top: 0;
				height: 30px;
				background-color: $blueShade;
				color: var(--py-neutral-7);
				border-radius: 2px;
				border: 1px solid lighten($color-blue, 30%);
			}
			.toggle-tooltip .label {
				white-space: nowrap;
				line-height: 32px;
				font-size: 14px;
				span {
					color: $color-blue;
					font-weight: 600;
				}
			}
			&:hover .toggle-tooltip {
				display: block;
			}
		}

		.label-box {
			color: var(--py-neutral-1);
			font-weight: 400;
			font-size: 14px;
			text-align: center;
			min-height: 18px;

			&.has-count {
				color: var(--py-neutral-5);
				cursor: pointer;
				border-radius: 3px;
				&:hover {
					background-color: lighten($color-blue, 47%);
					color: $color-blue;
				}
			}

			&.is-active {
				background-color: $color-blue;
				color: var(--py-neutral-0);
				&:hover {
					background-color: $color-blue;
					color: var(--py-neutral-0);
				}
			}

			.label {
			}
			.count {
				position: absolute;
				display: none;
				z-index: 1;
				width: 36px;
				left: 100%;
				top: 0;
				height: 100%;
				font-size: 13px;
				color: var(--py-neutral-0);
				background-color: var(--py-neutral-8);
				border-radius: 2px;
			}

			.count:after {
				content: " ";
				position: absolute;
				margin-top: -6px;
				right: 100%;
				top: 50%;
				height: 0;
				width: 0;
				pointer-events: none;
				border: solid transparent;
				border-color: transparent;
				border-right-color: var(--py-neutral-8);
				border-width: 6px;
			}

			&:hover .count {
				display: block;
			}
		}
	}
}
