/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
$padding: 20px;

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.cp-directory {
	.pagination-container {
		margin-top: 10px;
	}
	.search-results-container {
		margin-top: 20px;
	}
}
