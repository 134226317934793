/*--------------------------------------------------------------
1.0 - Color List
--------------------------------------------------------------*/
// red
$color-red-1: #f1634f;

// blue
$color-blue-1: #3e90c1;
$color-blue-2: #65a2e9;

// green
$color-green-1: #53a93f;

// orange
$color-orange-1: #f68429;

// yellow
$color-yellow-1: #f9ca6b;
$color-yellow-2: #f5e177;
$color-yellow-3: #ffff9a;
$color-yellow-4: #e3b505;

/*--------------------------------------------------------------
2.0 - Main Colors
--------------------------------------------------------------*/
$color-red: $color-red-1;
$color-blue: $color-blue-1;
$color-green: $color-green-1;
$color-orange: $color-orange-1;
$color-yellow: $color-yellow-4;
$color-pale-yellow: $color-yellow-2;

// /*--------------------------------------------------------------
// 3.0 - PY Colors
// --------------------------------------------------------------*/

$colorsMap: (
	"primary": (
		1: #e0edf5,
		2: #c5deec,
		3: #9bc5df,
		4: #65a6cd,
		5: #3e90c1,
		6: #3882ae,
		7: #2b6587,
		8: #193a4d,
		9: #102430,
	),
	"green": (
		1: #e3f1e0,
		2: #cbe5c5,
		3: #a6d29b,
		4: #75ba65,
		5: #53a93f,
		6: #4b9839,
		7: #3a762c,
		8: #214419,
		9: #152a10,
	),
	"red": (
		1: #fde6e3,
		2: #fbd0ca,
		3: #f8aea3,
		4: #f48272,
		5: #f1634f,
		6: #d95947,
		7: #a94537,
		8: #602820,
		9: #3c1914,
	),
	"orange": (
		1: #feebdd,
		2: #fcdabf,
		3: #fabf90,
		4: #f89d54,
		5: #f68429,
		6: #dd7725,
		7: #ac5c1d,
		8: #623510,
		9: #3e210a,
	),
	"yellow": (
		1: #fbf3d7,
		2: #f7e9b4,
		3: #f0d97d,
		4: #e9c437,
		5: #e3b505,
		6: #cca305,
		7: #9f7f04,
		8: #5b4802,
		9: #392d01,
	),
	"pale-yellow": (
		1: #fdfae9,
		2: #fcf6d6,
		3: #faefb8,
		4: #f7e792,
		5: #f5e177,
		6: #ddcb6b,
		7: #ac9e53,
		8: #625a30,
		9: #3d381e,
	),
	"neutral": (
		0: #ffffff,
		1: #f5f5f5,
		2: #e9eaec,
		3: #b7bbc2,
		4: #9197a1,
		5: #757d8a,
		6: #69717c,
		7: #525861,
		8: #2f3237,
		9: #1d1f23,
		10: #000000,
	),
);
