/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
$triangle: 7px;
$menu-header-bg: var(--py-neutral-1);

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.react-task-watcher {
	&.watcher-in-main {
		position: fixed;
		bottom: 20px;
		right: 90px;
		z-index: 10;
	}
	&.watcher-in-body {
		position: fixed;
		bottom: 10px;
		left: 10px;
		z-index: 10;
	}
	&.watcher-in-nav {
		float: right;
		margin-right: 10px;
		width: 25px;
		height: 25px;
	}
	&.watcher-in-dropdown {
		&:hover {
			background-color: var(--py-neutral-1);
		}
	}

	.task-runner-wrapper {
		.triangle {
			position: absolute;
			top: -($triangle * 2);
			left: 50%;
			margin-left: -($triangle);
			border-width: $triangle;
			border-style: solid;
		}

		.indicator-wrapper {
			position: absolute;
			top: 0;
			left: 0;
		}

		.status-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			transition: all 500ms;
		}

		.indicator {
			position: absolute;
		}

		.job-count {
			font-size: 13px;
			color: var(--py-neutral-8);
			font-weight: 600;
			&.no-jobs {
				color: var(--py-neutral-5);
				font-style: italic;
			}
		}

		.svg-wrapper {
			position: absolute;
			overflow: hidden;
			@extend .circle;
		}
	}
}

/*--------------------------------------------------------------
3.0 - Idling Styles
--------------------------------------------------------------*/
.react-task-watcher {
	.idle-wrapper {
		.svg-wrapper {
			border: 2px solid rgba(0, 0, 0, 0.15);
			svg {
				stroke: rgba(0, 0, 0, 0.15);
			}
		}

		.hover-info {
			position: absolute;
			display: none;
			top: 35px;
			height: 30px;
			background-color: $color-blue;
			border-radius: 3px;
			.triangle {
				border-color: transparent transparent $color-blue transparent;
			}
			.label {
				line-height: 30px;
				text-align: center;
				color: var(--py-neutral-0);
				font-size: 14px;
			}
		}
	}
}

/*--------------------------------------------------------------
4.0 - Running Styles
--------------------------------------------------------------*/
.react-task-watcher {
	.running-wrapper {
		cursor: pointer;
		.svg-wrapper {
			border: 2px solid $color-green;
			svg {
				stroke: $color-green;
			}
		}

		&:hover {
			.status-wrapper {
				background-color: rgba(255, 255, 255, 0.1);
			}
		}
	}
}

/*--------------------------------------------------------------
3.0 - Jobs List
--------------------------------------------------------------*/
.react-task-watcher {
	.task-runner-wrapper.is-open {
		background-color: $color-green;
		border-radius: 4px;

		.job-count {
			color: var(--py-neutral-0);
			font-weight: 400;
		}

		.running-wrapper {
			.svg-wrapper {
				border: 2px solid var(--py-neutral-0);
				svg {
					stroke: var(--py-neutral-0);
				}
			}
		}
	}

	.jobs-list-wrapper {
		.jobs-list {
			text-align: left;
			.job {
				padding: 4px 5px;
				.job-status {
					float: left;
					height: 20px;
					width: 20px;
					.status-dot {
						position: absolute;
						top: 8px;
						left: 8px;
						width: 4px;
						height: 4px;
						background-color: rgba(255, 255, 255, 0.8);
						@extend .circle;
					}
				}
				.job-label {
					margin-left: 20px;
					min-height: 20px;
					line-height: 20px;
					font-size: 13px;
					font-weight: 400;
					color: var(--py-neutral-0);
				}
			}
		}
	}
}
