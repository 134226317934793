/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.cp-chip {
	display: inline-block;
	padding: 3px 3px;
	.inner-chip {
		padding: 5px 10px;
		background-color: var(--py-neutral-1);
		@extend .circle;
	}

	.chip-label {
		min-height: 20px;
		line-height: 20px;
		font-size: 14px;
		color: var(--py-neutral-8);

		&.has-avatar {
			margin-left: 0;
		}
	}

	.chip-delete {
		position: absolute;
		cursor: pointer;
		margin-top: -10px;
		top: 50%;
		right: 5px;
		width: 20px;
		height: 20px;
		background-color: var(--py-neutral-3);
		@extend .circle;

		line-height: 20px;
		text-align: center;
		color: var(--py-neutral-1);
		font-size: 12px;

		&:hover {
			background-color: var(--py-neutral-4);
		}
	}

	.chip-avatar {
		position: absolute;
		top: 0;
		left: 0;
		width: 30px;
		height: 30px;
		background-color: var(--py-neutral-2);
		@extend .circle;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@extend .circle;
		}
	}
}
