/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.cp-spinner {
	display: inline-block;

	animation-name: spin;
	animation-duration: 1200ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;

	.quarter-circle {
		position: absolute;
		top: 0;
		left: 0;
		@extend .circle;
	}
	.quarter-circle {
		border: 2px solid rgba(0, 0, 0, 0.25);
		border-left: 2px solid var(--py-neutral-10);
	}

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(1080deg);
		}
	}
}
