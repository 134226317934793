/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.cp-form-progress {
	.progress-wrapper {
		margin: 0 auto;
	}
	.timeline {
		position: absolute;

		width: 100%;
		height: 2px;
		background-color: $color-blue;
	}

	.page-items {
		position: absolute;
		width: 100%;
		display: flex;
		justify-content: space-between;

		.progress-item {
			position: relative;
			.dot {
				position: absolute;
				background-color: $color-blue;
				border: 2px solid var(--py-neutral-0);
				@extend .circle;
			}

			.blip {
				position: absolute;
				width: 30px;
				height: 30px;
				background-color: $color-blue;
				@extend .circle;
			}
			.blip {
				animation-name: blipper;
				animation-duration: 1.5s;
				animation-fill-mode: both;
				animation-iteration-count: infinite;
			}
		}
	}

	@keyframes blipper {
		from {
			transform: scale(0.1, 0.1);
		}
		to {
			transform: scale(1.2, 1.2);
			background-color: transparent;
		}
	}
}
