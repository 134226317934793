/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.test-component-page {
	background-color: var(--py-neutral-1);
	.container {
		padding-top: 50px;
	}
	.editor-container {
		margin: 0 auto;
		padding: 20px;
		width: 400px;
		background-color: var(--py-neutral-0);
		border: 1px solid var(--py-neutral-2);
	}
	.public-DraftEditor-content {
		min-height: 300px;
		font-size: 15px;
		line-height: 20px;
	}
}
