/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
$br: 2px;

/*--------------------------------------------------------------
2.0 - Icons
--------------------------------------------------------------*/
.cp-toggle-icon {
	border: 1px solid var(--py-neutral-2);
	border-radius: $br;

	.toggle-option {
		position: absolute;
		top: 0;
		color: var(--py-neutral-4);
		cursor: pointer;
		&.is-selected .cp-icon {
			z-index: 10;
			color: var(--py-neutral-0);
		}
	}

	.toggle-option:hover {
		background-color: var(--py-neutral-1);
	}

	.selection {
		position: absolute;
		top: -1px;
		background-color: $color-blue;
		border-radius: $br;
	}
	.selection {
		transition: all 100ms;
	}
}

/*--------------------------------------------------------------
2.0 - Text
--------------------------------------------------------------*/
.cp-toggle-text {
	border: 1px solid var(--py-neutral-2);
	border-radius: $br;

	.toggle-option {
		position: absolute;
		top: 0;
		color: var(--py-neutral-5);
		cursor: pointer;
		font-size: 13px;
		text-align: center;
		&.is-selected .item-label {
			z-index: 10;
			color: var(--py-neutral-0);
		}
	}

	.toggle-option:hover {
		background-color: var(--py-neutral-1);
	}

	.selection {
		position: absolute;
		padding: 0;
		top: -1px;
		background-color: $color-blue;
		border-radius: $br;
	}
	.selection {
		transition: all 200ms ease-out;
	}

	.animate-left .selection {
		transform: skew(5deg);
	}
	.animate-right .selection {
		transform: skew(-5deg);
	}
}
