/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
@mixin status-cell() {
	display: inline-block;
}

@mixin icon-margin() {
	margin-right: 4px;
}

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.admin-employees-page-container {
	.main-page-title {
		margin: 0 0 15px 20px;
		line-height: 30px;
		font-size: 30px;
		font-weight: 300;
		color: var(--py-neutral-8);
	}
}

/*--------------------------------------------------------------
3.0 - Users Table
--------------------------------------------------------------*/
.admin-employees-page-container {
	.cp-table {
		.user-control-button {
			color: var(--py-neutral-3);
			cursor: pointer;
			&:hover {
				color: var(--py-neutral-8);
			}
		}

		.signed-in-status {
			@include status-cell();
			text-align: center;
			color: var(--py-neutral-5);
			font-style: italic;
			font-size: 13px;
		}

		.check-icon {
			@include status-cell();
			@include icon-margin();
			color: $color-green;
			text-align: center;
			.cp-icon {
				display: inline-block;
			}
		}

		.ex-icon {
			@include status-cell();
			@include icon-margin();
			color: $color-red;
			text-align: center;
			.cp-icon {
				display: inline-block;
			}
		}

		.envelope-icon {
			@include status-cell();
			@include icon-margin();
			color: $color-blue;
			text-align: center;
			.cp-icon {
				display: inline-block;
			}
		}
	}
}

/*--------------------------------------------------------------
4.0 - Filters
--------------------------------------------------------------*/
.admin-employees-page-container {
	.filters-button {
		position: absolute;
		cursor: pointer;
		top: 0;
		width: 30px;
		height: 30px;
		color: var(--py-neutral-3);
		border: 1px solid var(--py-neutral-2);
		border-radius: 2px;
		&:hover {
			background-color: var(--py-neutral-1);
			color: $color-blue;
		}
	}

	.filters-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		z-index: 1000;
		background-color: var(--py-neutral-0);
		border-right: 1px solid var(--py-neutral-2);

		.filter-box {
			margin-top: 20px;
			padding-top: 10px;
			border-top: 1px solid var(--py-neutral-1);
			&:first-child {
				margin-top: 0;
				padding-top: 0;
				border-top: none;
			}

			.label-container {
				margin-bottom: 10px;
				font-size: 15px;
				font-weight: 300;
				line-height: 20px;
				color: var(--py-neutral-8);
			}

			.filter-options {
				margin-top: 5px;
			}
		}

		.button-container {
			position: fixed;
			bottom: 0;
			left: 0;
			height: 46px;
			background-color: var(--py-neutral-0);
			border-top: 1px solid var(--py-neutral-2);
			button.newpy.button {
				position: absolute;
				top: 5px;
				left: 5px;
				height: 36px;
				box-sizing: border-box;
				border: none;
			}
		}

		.close-button {
			position: absolute;
			cursor: pointer;
			top: 10px;
			right: 10px;
			width: 20px;
			height: 20px;
			color: var(--py-neutral-3);
			@extend .circle;
			border: 3px solid var(--py-neutral-0);
			&:hover {
				color: var(--py-neutral-6);
				background-color: var(--py-neutral-1);
				border: 3px solid var(--py-neutral-1);
			}
		}

		.clear-date {
			position: absolute;
			cursor: pointer;
			top: 15px;
			right: 0;
			font-size: 12px;
			font-weight: 600;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

/*--------------------------------------------------------------
4.0 - User Card
--------------------------------------------------------------*/
.admin-employees-user-card-container {
	margin: 0 auto;
	padding: 30px 30px;
	max-width: 360px;
	background-color: var(--py-neutral-0);
	border-radius: 3px;

	.avatar-container {
		text-align: center;
	}
	.employee-name {
		margin-top: 10px;
		text-align: center;
		font-size: 16px;
		font-weight: 600;
	}

	.edit-inputs {
		.label {
			font-size: 13px;
			font-weight: 600;
		}

		.change-permissions-wrapper {
			margin-top: 20px;
			.label {
				margin-bottom: 3px;
			}
		}
	}

	.permission-description {
		margin-top: 40px;
		h3 {
			font-weight: bold;
			margin-bottom: 10px;
		}

		.permission-description-content {
			font-size: 13px;
			font-style: italic;
		}
	}

	.manage-button {
		position: absolute;
		top: 10px;
		right: 10px;
	}
}
