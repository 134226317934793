/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
$headWidth: auto;
$formWidth: auto;

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.cn-create-form {
	//
}

/*--------------------------------------------------------------
3.0 - Head Container
--------------------------------------------------------------*/
.cn-create-form {
	.head-content {
		margin: 0 auto 0 auto;
		width: $headWidth;
		text-align: center;
		.cp-icon {
			text-align: center;
			font-size: 45px;
			color: $color-blue;
		}
		h1 {
			font-size: 20px;
			line-height: 40px;
			font-weight: 700;
			text-transform: uppercase;
			color: var(--py-neutral-6);
		}
		p {
			font-size: 15px;
			line-height: 20px;
			font-weight: 300;
			color: var(--py-neutral-5);
		}
	}
}

/*--------------------------------------------------------------
4.0 - Form Inputs
--------------------------------------------------------------*/
.cn-create-form {
	.form-inputs-container {
		margin: 30px auto 0 auto;
		width: $formWidth;

		& > .input-container,
		& > .double-box > .input-container {
			margin-top: 16px;
			&:first-child {
				margin-top: 0;
			}

			.primary-label {
				label {
					display: flex;
					gap: 4px;
				}

				.optional-tag {
					display: inline-block;
					margin-left: 8px;
					font-size: 12px;
					line-height: 16px;
					font-weight: 400;
					color: var(--py-neutral-6);
				}
			}

			.secondary-label {
				margin-bottom: 6px;
				font-size: 12px;
				line-height: 15px;
				color: var(--py-neutral-6);
			}

			.not-editable-value {
				margin-left: 5px;
				line-height: 16px;
				font-weight: 400;
				color: var(--py-neutral-8);
				font-style: italic;
			}

			&.checkbox-input-container {
				min-height: 16px;

				.component-input-checkbox {
					float: left;
				}

				.checkbox-label-container {
					margin-left: 32px;
					padding-top: 2px;

					.primary-label-container {
						display: flex;
						gap: 4px;
					}
				}

				.secondary-label {
					margin-bottom: 0;
				}
			}
		}
	}
}

/*--------------------------------------------------------------
5.0 - Form Actions
--------------------------------------------------------------*/
.cn-create-form {
	.action-buttons-container {
		margin: 16px auto 0 auto;
		width: $formWidth;
		text-align: center;
		button.newpy.button {
			display: inline-block;
			margin: 0 4px;
			width: 120px;
			height: 34px;
		}
	}
}
