/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.component-toggle-input {
	.options-container {
	}

	.options-container {
		.option {
			position: absolute;
			cursor: pointer;
			top: 0;
			height: 100%;
			text-align: center;
			color: var(--py-neutral-4);
			border: 1px solid var(--py-neutral-2);
		}
		.icon {
			line-height: 16px;
			font-size: 12px;
		}
		.name {
			line-height: 16px;
			font-size: 14px;
			font-weight: 300;
		}

		.option:hover {
			background-color: var(--py-neutral-1);
			color: var(--py-neutral-5);
		}
		.option.selected {
			background-color: $color-blue;
			color: var(--py-neutral-0);
			border: 1px solid darken($color-blue, 15%);
			z-index: 1;
		}

		.tbl {
			position: absolute;
			display: table;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		.tbl .cell {
			display: table-cell;
			vertical-align: middle;
		}
	}
}
