/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
$bludeShade: lighten($color-blue, 46%);

/*--------------------------------------------------------------
2.0 - Mention Classes
--------------------------------------------------------------*/
.mention-span-block {
	display: inline-block;
	padding: 0 2px;
	background-color: $bludeShade;
	color: $color-blue;
	font-weight: 400;
	border-radius: 2px;
}

.mentionSuggestions {
	border: 1px solid var(--py-neutral-2);
	margin-top: 1.75em;
	position: absolute;
	min-width: 220px;
	max-width: 440px;
	background: var(--py-neutral-0);
	border-radius: 2px;
	// box-shadow: 0px 4px 30px 0px rgba(220,220,220,1);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
	cursor: pointer;
	padding-top: 8px;
	padding-bottom: 8px;
	z-index: 2;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	transform: scale(0);
}

.mentionSuggestions .mention-container {
	padding: 5px 10px;
	transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.mentionSuggestionsEntryFocused {
	background-color: $bludeShade;
}

.mentionSuggestions .mention-container:hover {
	background-color: $bludeShade;
}

.mentionSuggestions .mention-container .mention-label {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 368px;
	font-size: 15px;
	line-height: 20px;
	font-weight: 600;
}
