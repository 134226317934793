/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.component-filter-slideout {
	position: fixed;
	top: 0;
	left: 0;
	width: 330px;
	height: 100%;
	background-color: var(--py-neutral-0);
	border-right: 1px solid var(--py-neutral-2);
	z-index: 1;
}
.component-filter-slideout {
	.filter-content-wrapper {
		margin: 40px 20px 0 20px;
		padding-bottom: 80px;
	}
}

/*--------------------------------------------------------------
3.0 - Content
--------------------------------------------------------------*/
.component-filter-slideout {
	.filter-content-wrapper {
		.description {
			margin-top: 10px;
		}
	}
}
