/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.companyreporting-page {
	.query-builder-container {
		// padding: 10px 10px; background-color: var(--py-neutral-1); border: 1px solid var(--py-neutral-2); box-sizing: border-box;

		.query-designer {
			margin-top: 10px;
		}
	}
}

/*--------------------------------------------------------------
3.0 - Query Row
--------------------------------------------------------------*/
.companyreporting-page {
	.query-block-container {
		.query-block {
			padding: 10px;
			background-color: var(--py-neutral-0);
			border: 1px solid var(--py-neutral-2);
			border-left: 3px solid $color-blue;
			border-radius: 3px;
			&.is-or {
				border-top: none;
				border-left: 3px solid $color-green;
				border-radius: 0 3px 3px 3px;
			}
		}

		.logic-label {
			position: absolute;
			top: 16px;
			left: 0;
			font-size: 12px;
			color: var(--py-neutral-6);
			line-height: 20px;
			width: 23px;
			left: -32px;
			text-align: right;
			font-weight: 600;
		}

		.field-picker-container {
			position: absolute;
			top: 0;
			left: 0;
			height: 32px;
		}
		.value-picker-container {
			position: absolute;
			top: 0;
			height: 32px;
		}

		.controls {
			position: absolute;
			top: 1px;
			height: 30px;

			.control {
				position: absolute;
				box-sizing: border-box;
				top: 0;
				width: 30px;
				height: 30px;
				line-height: 30px;
				text-align: center;
				border: 1px solid var(--py-neutral-2);
				border-radius: 4px;
				cursor: pointer;
			}

			.control.or {
				left: 0;
				font-weight: 600;
				font-size: 13px;
			}
			.control.or:hover {
				background-color: lighten($color-green, 46%);
				color: $color-green;
				border: 1px solid lighten($color-green, 46%);
			}

			.control.delete {
				right: 0;
				color: var(--py-neutral-5);
				font-size: 14px;
			}
			.control.delete:hover {
				background-color: lighten($color-red, 30%);
				color: $color-red;
				border: 1px solid lighten($color-red, 30%);
			}
		}
	}
}

/*--------------------------------------------------------------
4.0 - Add Button
--------------------------------------------------------------*/
.companyreporting-page {
	.query-builder-container {
		.add-button {
			padding: 0 20px;
			box-sizing: border-box;
			height: 32px;
			background-color: var(--py-neutral-0);
			border: 2px solid $color-blue;
			border-radius: 3px;
			font-size: 13px;
			color: $color-blue;
			font-weight: 600;
			cursor: pointer;
			outline: none;
		}

		.add-button:hover {
			background-color: lighten($color-blue, 38%);
		}
	}
}

/*--------------------------------------------------------------
4.0 - As Of Query
--------------------------------------------------------------*/
.companyreporting-page {
	.query-builder-container {
		.as-of-query {
			margin-top: 10px;
			padding-top: 10px;
			border-top: 1px solid var(--py-neutral-1);

			.label {
				display: inline-block;
				line-height: 32px;
				font-size: 14px;
				font-weight: 600;
				color: var(--py-neutral-8);
			}
			.date-box {
				position: absolute;
				top: 0;
				left: 44px;
				height: 32px;
				width: 250px;
			}

			.include-terminated {
				position: absolute;
				top: 0;
				right: 0;
				width: 250px;
				height: 32px;
				font-size: 13px;
				text-align: right;
				line-height: 32px;
				label {
					margin-right: 5px;
					font-weight: 600;
				}
				input {
					vertical-align: middle;
				}
			}

			.info {
				margin-top: 10px;
				font-size: 12px;
				color: var(--py-neutral-6);
			}
		}
	}
}

/*--------------------------------------------------------------
5.0 - Button Controls
--------------------------------------------------------------*/
.companyreporting-page {
	.query-builder-container {
		.builder-controls {
			margin-top: 10px;
			padding-top: 10px;
			border-top: 1px solid var(--py-neutral-1);

			.control {
				position: absolute;
				top: 0;
			}
			.apply-button {
				right: 0;
			}
			.save-button {
				right: 110px;
			}
		}
	}
}
