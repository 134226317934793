/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
$padding: 20px;

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.review-cycle-view-page {
	.border {
		margin-top: 5px;
		width: 250px;
		height: 1px;
		background-color: var(--py-neutral-1);
	}
}

/*--------------------------------------------------------------
3.0 - Details
--------------------------------------------------------------*/
.review-cycle-view-page {
	.details {
		h1 {
			font-size: 30px;
			line-height: 30px;
			font-weight: 300;
			color: var(--py-neutral-8);
		}
		.menu-row {
			display: flex;
			align-items: center;
			.cp-spinner {
				margin-left: 16px;
			}
		}
		.begin-date {
			margin-top: 5px;
			font-size: 12px;
			line-height: 20px;
			font-weight: 400;
			color: var(--py-neutral-6);
			.date {
				font-weight: 700;
			}
		}

		.info {
			font-size: 14px;
			line-height: 24px;
			font-weight: 300;
			color: var(--py-neutral-8);
			span {
				margin-right: 5px;
				font-weight: 600;
				font-size: 12px;
			}
		}
	}
}

/*--------------------------------------------------------------
4.0 - Visuals
--------------------------------------------------------------*/
.review-cycle-view-page {
	.visuals {
		.visual {
			margin-top: 20px;
			.label {
				margin-bottom: 5px;
				font-size: 13px;
				line-height: 15px;
				font-weight: 600;
				color: var(--py-neutral-8);
			}

			.progress-counts {
				position: absolute;
				top: 0;
				right: 0;
				font-size: 12px;
				color: var(--py-neutral-5);
				line-height: 15px;
			}

			.gutter {
				background-color: var(--py-neutral-1);
				@extend .circle;
				.filler {
					position: absolute;
					top: 0;
					left: 0;
					background-color: $color-blue;
					transition: all 250ms;
					@extend .circle;
				}
			}

			&:first-child {
				margin-top: 0;
			}
		}

		.pie-container {
			float: left;
			width: 120px;

			& * {
				outline: none;
			}
		}

		.pie-legend-container {
			margin-left: 130px;
			.legend {
				.color {
					position: absolute;
					top: 8px;
					left: 8px;
					width: 14px;
					height: 14px;
					background-color: var(--py-neutral-10);
					@extend .circle;
				}
				.legend-label {
					margin-left: 28px;
					line-height: 30px;
					font-weight: 600;
					font-size: 13px;
				}
			}
		}
	}
}

/*--------------------------------------------------------------
5.0 - Reviews Table
--------------------------------------------------------------*/
.review-cycle-view-page {
	.reviews-table {
		margin-top: 20px;
		border-top: 1px solid var(--py-neutral-2);

		.filters-button {
			position: absolute;
			cursor: pointer;
			top: 0;
			width: 30px;
			height: 30px;
			color: var(--py-neutral-3);
			border: 1px solid var(--py-neutral-2);
			border-radius: 2px;
			&:hover {
				background-color: var(--py-neutral-1);
				color: $color-blue;
			}
		}

		.filtered-count {
			position: absolute;
			bottom: -10px;
			right: 0;
			font-size: 13px;
			color: var(--py-neutral-5);
		}

		.bool-cell {
			text-align: center;
			.cp-icon {
				display: inline-block;
				color: $color-green;
			}
		}

		.progress-bar {
			background-color: var(--py-neutral-2);
			height: 6px;
			@extend .circle;
			.filler {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				background-color: $color-blue;
				transition: all 250ms;
				@extend .circle;
			}
		}

		.locked-icon {
			position: absolute;
			top: 0;
			right: 0;
			height: 30px;
			width: 30px;
			.cp-icon {
				margin: 0 auto;
			}
			.awesome-icon {
				line-height: 30px;
			}
		}

		.locked-icon {
			background-color: var(--py-neutral-0);
		}
		.public_fixedDataTableRow_highlighted .locked-icon {
			background-color: var(--py-neutral-1);
		}

		.date-value {
			font-size: 12px;
			font-weight: 600;
		}

		.remove-button {
			cursor: pointer;
			height: 22px;
			width: 34px;
			border: none;
			border-radius: 2px;
			outline: none;
			background-color: transparent;
			color: var(--py-neutral-3);
			.cp-icon {
				position: absolute;
				left: 9px;
				top: 3px;
			}

			&:hover {
				background-color: $color-red;
				color: var(--py-neutral-0);
			}
			&.text {
				width: auto;
				color: $color-red;
				background-color: transparent;
				font-weight: 600;
			}
			&.text:hover {
				background-color: $color-red;
				color: var(--py-neutral-0);
			}
		}

		.view-button {
			display: block;
			margin: 0;
			padding: 0;
			height: 26px;
			width: 100%;
			border: none;
			color: var(--py-neutral-0);
			background-color: $color-blue;
			border-radius: 2px;
			line-height: 26px;
			text-align: center;
			font-size: 13px;
			font-weight: 600;

			&:hover {
				background-color: lighten($color-blue, 10%);
			}
		}
	}
}

/*--------------------------------------------------------------
6.0 - Filter Slideout
--------------------------------------------------------------*/
.review-cycle-view-page-container {
	.review-cycle-filters {
		position: absolute;
		top: 0;
		left: 0;
		background-color: var(--py-neutral-0);
		border-right: 1px solid var(--py-neutral-2);
		z-index: 10;

		.filter-box {
			margin-top: 20px;
			padding-top: 10px;
			border-top: 1px solid var(--py-neutral-1);
			&:first-child {
				margin-top: 0;
				padding-top: 0;
				border-top: none;
			}

			.label-container {
				font-size: 15px;
				font-weight: 300;
				line-height: 20px;
				color: var(--py-neutral-8);
			}

			.filter-options {
				margin-top: 5px;
			}
		}

		.button-container {
			position: fixed;
			bottom: 0;
			left: 0;
			height: 46px;
			background-color: var(--py-neutral-0);
			border-top: 1px solid var(--py-neutral-2);
			button.newpy.button {
				position: absolute;
				top: 5px;
				left: 5px;
				height: 36px;
				box-sizing: border-box;
				border: none;
			}
		}

		.close-button {
			position: absolute;
			cursor: pointer;
			top: 10px;
			right: 10px;
			width: 20px;
			height: 20px;
			color: var(--py-neutral-3);
			@extend .circle;
			border: 3px solid var(--py-neutral-0);
			&:hover {
				color: var(--py-neutral-6);
				background-color: var(--py-neutral-1);
				border: 3px solid var(--py-neutral-1);
			}
		}

		.clear-date {
			position: absolute;
			cursor: pointer;
			top: 15px;
			right: 0;
			font-size: 12px;
			font-weight: 600;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

/*--------------------------------------------------------------
7.0 - Progress Bar
--------------------------------------------------------------*/
.review-cycle-view-page-container {
	.is-loading-container {
		margin-top: 10px;
		padding-top: 50px;
		border-top: 1px solid var(--py-neutral-1);

		.loading-title {
			font-size: 26px;
			font-weight: 300;
			color: $color-blue;
			line-height: 30px;
			text-align: center;
		}
		.loading-subtitle {
			margin-top: 10px;
			font-size: 15px;
			font-weight: 400;
			color: var(--py-neutral-4);
			line-height: 22px;
			text-align: center;
		}

		.progress-bar-container {
			margin: 30px auto 0 auto;
			.cp-progress-bar {
				margin: 0 auto;
			}
		}
	}
}

/*--------------------------------------------------------------
8.0 - Cycle is Closed
--------------------------------------------------------------*/
.review-cycle-view-page-container {
	.cycle-is-closed {
		margin-top: 20px;
		.label {
			display: inline-block;
			padding: 6px 20px;
			background-color: $color-red;
			color: var(--py-neutral-0);
			border-radius: 3px;
			font-size: 14px;
			line-height: 20px;
		}
	}
}

/*--------------------------------------------------------------
8.0 - DNE Container
--------------------------------------------------------------*/
.review-cycle-view-page-container {
	.dne-container {
		margin: 0 auto;
		padding-top: 100px;
		width: 600px;
		text-align: center;
		color: $color-red;
		font-weight: 600;
	}
}
