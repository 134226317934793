/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.cp-drop-down-menu {
	display: block;

	.button-container {
		cursor: pointer;
		border: 1px solid var(--py-neutral-1);
		border-radius: 3px;
		.label {
			margin: 0 10px;
			font-size: 12px;
			font-weight: 600;
			color: var(--py-neutral-6);
			text-transform: uppercase;
		}
		.menu-icon {
			position: absolute;
			top: 0;
			right: 10px;
			color: var(--py-neutral-3);
			font-size: 14px;

			.cp-icon svg {
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}

	.button-container:hover {
		border: 1px solid var(--py-neutral-2);
	}
	&.is-open .button-container {
		border: 1px solid var(--py-neutral-2);
	}

	.menu-options {
		position: absolute;
		z-index: 10;
		box-sizing: border-box;
		left: 0;
		width: 100%;
		background-color: var(--py-neutral-0);
		border: 1px solid var(--py-neutral-2);
		.menu-option {
			cursor: pointer;
			padding: 0 10px;
			font-size: 14px;
			color: var(--py-neutral-5);
		}
		.menu-icon {
			position: absolute;
			right: 10px;
			color: var(--py-neutral-3);
		}

		.menu-option:hover {
			background-color: var(--py-neutral-1);
			color: var(--py-neutral-8);
		}

		&:hover .menu-option:hover .menu-icon {
			color: $color-blue;
		}
	}
}
