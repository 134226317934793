/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.companyreporting-page {
	.query-controls-container {
		box-sizing: border-box;

		.fw {
			width: 100%;
		}
		.abs {
			position: absolute;
		}

		.controls-head {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			height: 32px;
		}

		.container {
			margin-top: 10px;
			padding: 10px;
			border: 1px solid var(--py-neutral-2);
		}
		.container.first {
			margin-top: 0;
		}

		.donut-container {
			position: "relative";
			top: 20px;
			height: 200px;
			width: 198px;
			margin: 0 auto;
		}

		.current-query-string {
			font-size: 13px;
			line-height: 18px;
			color: var(--py-neutral-6);
			font-weight: 400;
			position: "relative";
			top: 10px;
		}
		.current-user-count {
			margin-bottom: 10px;
			margin-top: 20px;
			font-size: 12px;
			line-height: 18px;
			color: var(--py-neutral-6);
			text-align: center;
			position: "relative";
			top: 10px;
			span {
				font-weight: 600;
				color: $color-green;
			}
		}
	}
}
