/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.component-input-checkbox {
	display: inline-block;
	vertical-align: middle;

	.checkbox {
		cursor: pointer;
		height: 18px;
		width: 18px;
		border-radius: 2px;
		line-height: 18px;
		text-align: center;
		font-size: 13px;
		background-color: var(--py-neutral-0);
		color: $color-green;
		border: 1px solid var(--py-neutral-3);
	}

	.checkbox.is-radio {
		border-radius: 50px;
	}

	.checkbox.is-checked {
		background-color: $color-blue;
		color: var(--py-neutral-0);
		border: 1px solid $color-blue;
	}

	.checkbox.is-checked:hover {
		background-color: lighten($color-blue, 7%);
		border: 1px solid lighten($color-blue, 7%);
	}

	&.is-disabled {
		.checkbox {
			cursor: not-allowed;
			opacity: 0.5;
		}
	}
}
