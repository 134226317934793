/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
#react-slideout-root {
	.page-slideout-portal {
		position: fixed;
		top: 0;
		left: 0;
		width: 300px;
		height: 100%;
		background-color: var(--py-neutral-0);
		z-index: 100;

		.portal-content {
			margin: 30px 20px 0 20px;
		}

		.close-curtain {
			position: absolute;
			cursor: pointer;
			top: 20px;
			right: 20px;
			color: var(--py-neutral-4);
			&:hover {
				color: var(--py-neutral-6);
			}
		}
	}
}
