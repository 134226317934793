/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
@import "../config/colors";

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.cp-feedback-viewer {
	.feedback-field-box {
		padding: 16px;
		border-bottom: 1px solid var(--py-neutral-2);
		display: flex;
		align-items: baseline;
		gap: 8px;
		flex-wrap: wrap;

		.user-image {
			float: left;
			width: 22px;
			height: 22px;
		}
		.user-name {
			margin-left: 26px;
			height: 22px;
			line-height: 22px;
		}
		.user-name a {
			display: inline-block;
			font-weight: 300;
			color: var(--py-neutral-8);
			font-size: 14px;
		}
		.user-name a:hover {
			color: $color-blue;
			text-decoration: underline;
		}
	}

	.user-block {
		display: inline-block;
		margin-right: 5px;
	}
	.user-block:last-child {
		margin-right: 0;
	}
	.user-block:after {
		content: ",";
		position: absolute;
		bottom: 7px;
		right: 0;
		font-size: 12px;
		color: var(--py-neutral-3);
	}
	.user-block:last-child:after {
		display: none;
	}

	.content .hashtag {
		color: $color-blue;
		font-weight: 600;
	}

	.response-label {
		display: flex;
		gap: 4px;
	}

	.main-feedback-content {
		margin-top: 16px;
		margin-bottom: 16px;
		border-radius: 8px;
	}

	.feedback-response {
		padding: 8px;
		background: var(--py-neutral-1);
		margin-top: 24px;
	}

	.last-updated {
		margin-top: 16px;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
	}
	.last-updated span {
		margin-right: 2px;
		font-weight: 600;
	}
}

/*--------------------------------------------------------------
3.0 - Content
--------------------------------------------------------------*/
.cp-feedback-viewer {
	.content {
		margin-top: 16px;
		font-size: 14px;
		line-height: 20px;
		font-weight: 300;
		color: var(--py-neutral-8);
	}
	.content {
		white-space: pre-line;
	}
	.content .tag {
		color: getBrandColor("primary", 5);
	}
}

/*--------------------------------------------------------------
3.0 - Controls
--------------------------------------------------------------*/
.cp-feedback-viewer {
	.info-container {
		margin-top: 8px;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.feedback-chips {
			margin-top: 5px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 8px;
		}

		.sharing-message {
			padding: 4px 8px;
			font-size: 12px;
			line-height: 14px;
			font-weight: 600;
			color: getBrandColor("primary", 5);
			background-color: getBrandColor("primary", 1);
			@extend .circle;
		}

		.controls-box {
			display: flex;
		}
	}

	.request-feedback-message-container {
		.request-content {
			margin-top: 16px;
			margin-bottom: 8px;
		}
	}
}
