/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.companyreporting-page {
	.results-container {
		margin-top: 10px;
	}
}

/*--------------------------------------------------------------
2.0 - Failed Response
--------------------------------------------------------------*/
.companyreporting-page {
	.failed-response-container {
		padding-top: 50px;
		text-align: center;
		font-size: 30px;
		color: $color-yellow;

		.error-message {
			margin-top: 30px;
			font-size: 18px;
			color: var(--py-neutral-8);
			font-weight: 600;
		}
	}
}
