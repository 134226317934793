/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
$topSpacing: 80px;
$modalWidth: 360px;

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.component-modals-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	background: rgba(255, 255, 255, 0.5);
}
.component-modals-container {
	background: rgba(0, 0, 0, 0.75);
	background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.45) 1%, rgba(0, 0, 0, 0.8) 100%);
}

.component-modals-container {
	animation: fadein 350ms;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/*--------------------------------------------------------------
3.0 - Wrapper
--------------------------------------------------------------*/
.component-modals-container {
	.modal-wrapper {
		margin: 0 auto;
		padding-top: $topSpacing;
		width: $modalWidth;
	}
}

/*--------------------------------------------------------------
3.0 - modal components
--------------------------------------------------------------*/
.component-modals-container {
	.component-modal {
		padding-bottom: 20px;
		background-color: var(--py-neutral-0);
		border-radius: 6px;
		animation: bouncein 500ms linear;

		.modal-head {
			height: 40px;
			background-color: var(--py-neutral-1);
			border-bottom: 1px solid var(--py-neutral-3);
			border-radius: 6px 6px 0px 0px;
		}
		.close-box {
			position: absolute;
			cursor: pointer;
			right: 10px;
			top: 10px;
			height: 20px;
			width: 20px;
		}
		.close-box {
			line-height: 20px;
			text-align: center;
			color: var(--py-neutral-3);
		}
		.close-box:hover {
			color: var(--py-neutral-6);
		}
		.modal-text {
			margin: 20px;
			color: var(--py-neutral-6);
			font-size: 14px;
			font-weight: 600;
			text-align: center;
		}
		.modal-buttons {
			text-align: center;
		}
		.modal-buttons .button {
			display: inline-block;
			margin: 0 5px;
			height: 29px;
		}

		@keyframes bouncein {
			from,
			60%,
			75%,
			90%,
			to {
				animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
			}

			0% {
				opacity: 0;
				transform: translate3d(0, -3000px, 0);
			}

			60% {
				opacity: 1;
				transform: translate3d(0, 25px, 0);
			}

			75% {
				transform: translate3d(0, -10px, 0);
			}

			90% {
				transform: translate3d(0, 5px, 0);
			}

			to {
				transform: none;
			}
		}
	}
}
