/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
$padding: 20px;

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.view-group-page-container {
	margin-left: 250px;
	.page-wrapper {
	}
}
