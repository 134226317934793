/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
$padding: 20px;

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.companyreporting-page {
	.save-builder-container {
		padding: 10px;
		margin-top: 10px;
		border: 1px solid var(--py-neutral-2);
		box-sizing: border-box;

		.input-container {
			margin-top: 20px;
			&.first {
				margin-top: 0;
			}

			.label {
				margin-bottom: 2px;
				font-size: 14px;
				line-height: 20px;
				font-weight: 600;
				color: var(--py-neutral-8);
			}
			.info {
				position: absolute;
				padding: 0 8px;
				top: 0;
				right: 0;
				font-size: 12px;
				line-height: 18px;
				color: var(--py-neutral-0);
				background-color: $color-blue;
				opacity: 0;
				transition: all 300ms;
				@extend .circle;
			}
			.info.show {
				opacity: 1;
			}
		}

		.tag-pool {
			margin-top: 10px;
		}
		.save-container {
			margin-top: 10px;
			padding-top: 10px;
			border-top: 1px solid var(--py-neutral-1);
			button {
				width: 100%;
			}
		}
	}
}
