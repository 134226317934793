/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.cp-directory {
	.search-container {
		.select-group-button {
			position: absolute;
			cursor: pointer;
			bottom: 0;
			width: 30px;
			height: 30px;
			border: 1px solid var(--py-neutral-2);
			border-radius: 2px;
			color: var(--py-neutral-4);
			&:hover {
				background-color: var(--py-neutral-1);
				color: $color-blue;
			}
		}

		.switch-to-selected {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 240px;
			.switch-label {
				margin-right: 25px;
				font-size: 13px;
				font-weight: 600;
				line-height: 20px;
				text-align: right;
			}
			.switch-box {
				position: absolute;
				top: 0;
				right: 0;
			}
		}
	}
}

/*--------------------------------------------------------------
3.0 - Group Search
--------------------------------------------------------------*/
.page-curtain-portal {
	.group-search-curtain {
		padding-top: 40px;
		transition: left 250ms cubic-bezier(0.36, 1, 0.39, 1);

		.content-wrapper {
			margin: 0 auto;
		}

		.input-container {
			margin: 0 auto;
			.search-label {
				margin-bottom: 10px;
				font-weight: 300;
				font-size: 30px;
				line-height: 40px;
				color: var(--py-neutral-8);
				.cp-icon {
					margin-right: 10px;
					display: inline-block;
					top: 5px;
					color: $color-blue;
				}
				.label {
					display: inline-block;
				}
			}
			.react-input {
				padding: 0 10px;
				height: 40px;
			}
		}

		.group-results-container {
			margin-top: 20px;

			.group-row {
				padding: 10px;
				color: var(--py-neutral-8);
				border-radius: 3px;
				cursor: pointer;
				&:hover {
					background-color: $color-blue;
					color: var(--py-neutral-0);
				}
			}

			.group-name {
				font-size: 20px;
			}
		}
	}
}
