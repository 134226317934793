/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
@import "../config/colors";
@import "../config/extensions";

/*--------------------------------------------------------------
1.0 - Hide the action box
--------------------------------------------------------------*/
.py-main-action-br-container {
	display: none !important;
}

/*--------------------------------------------------------------
2.0 - Variables
--------------------------------------------------------------*/
$width: 50px;
$height: 50px;
$color: getBrandColor("red", 4);
$color: $color-blue;

/*--------------------------------------------------------------
3.0 - Feedback Tab
--------------------------------------------------------------*/
.component-tabs-container {
	position: fixed;
	bottom: 20px;
	right: 0;
	width: $width;
}
.component-tabs-container {
	z-index: 100;
}
.component-tabs-container .tab {
	position: absolute;
	right: 0;
	width: 100%;
	height: $height;
	transition: all 100ms;
}
.component-tabs-container .tab {
	cursor: pointer;
	background-color: $color;
	border-radius: 3px 0 0 3px;
}
.component-tabs-container .tab {
	border-top: 2px solid $color;
	border-left: 2px solid $color;
	border-bottom: 2px solid $color;
}
.component-tabs-container .tab .tab-icon {
	position: absolute;
	left: 10px;
	top: ($height - 30px) * 0.5;
	height: 30px;
	width: 30px;
}
.component-tabs-container .tab .tab-icon {
	line-height: 30px;
	text-align: center;
	font-size: 20px;
	color: #fff;
}

.component-tabs-container .tab.hide {
	right: -($width + 10px);
}

.component-tabs-container .tab:hover {
	background-color: lighten($color, 40%);
	background-color: #fff;
}
.component-tabs-container .tab:hover .tab-icon {
	color: $color;
}
