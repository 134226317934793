/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
@import "../../config/colors";
/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.feedback-page {
	.feedback-table {
		// hide box shadows
		overflow: hidden;
		max-width: 1096;

		.feedback-list {
			height: 488px;
			width: 1096px;
			overflow-y: scroll;
			-ms-overflow-style: none;
			scrollbar-width: none;
		}

		.feedback-list::-webkit-scrollbar {
			// chrome hide scrollbar
			display: none;
		}

		.feedback-viewer {
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			width: 432px;
			overflow-y: scroll;
			-ms-overflow-style: none; /* IE and Edge hide scrollbar*/
			scrollbar-width: none; /* Firefox hide scrollbar*/
			background-color: var(--py-neutral-0);
			border-left: 1px solid var(--py-neutral-2);
		}

		.feedback-viewer::-webkit-scrollbar {
			// chrome hide scrollbar
			display: none;
		}

		.feedback-block {
			.feedback-type {
				position: absolute;
				top: 50%;
				margin-top: -10px;
				right: 10px;
				width: 80px;
				height: 20px;
				background-color: var(--py-neutral-2);
			}
			.feedback-type {
				background-color: $color-orange;
				border-radius: 4px;
				color: var(--py-neutral-0);
				font-size: 12px;
				text-align: center;
				line-height: 20px;
			}
		}

		.feedback-block.is-active {
			background-color: $color-blue;
			.subject-name {
				color: var(--py-neutral-0);
			}
			.feedback-date {
				color: var(--py-neutral-0);
			}
		}

		.feedback-viewer {
			.head-block {
				margin-top: 5px;
				height: 0;
			}
			.close-box {
				position: absolute;
				cursor: pointer;
				right: 10px;
				top: 3px;
				z-index: 1;
			}

			.viewer-content {
				margin: 0 10px 0 20px;
				padding-bottom: 30px;
			}
		}
	}
}

/*--------------------------------------------------------------
3.0 - Controls
--------------------------------------------------------------*/
.feedback-page {
	.feedback-table {
		.info-container {
			margin-top: 10px;
			padding-bottom: 5px;
			// padding-bottom: 10px; border-bottom: 1px solid var(--py-neutral-1);

			.sharing-message {
				display: inline-block;
				margin-top: 3px;
				padding: 5px 14px;
			}
			.sharing-message {
				font-size: 13px;
				line-height: 14px;
				font-weight: 600;
				color: getBrandColor("primary", 5);
				background-color: getBrandColor("primary", 1);
				@extend .circle;
			}
		}
	}
}

/*--------------------------------------------------------------
4.0 - Load More button
--------------------------------------------------------------*/
.feedback-page {
	.pagination-container {
		$br: 3px;
		$color: $color-blue;

		padding: 15px 0;
		background-color: var(--py-neutral-0);
		text-align: center;

		.step-button {
			display: inline-block;
			vertical-align: middle;
			cursor: pointer;
			width: 70px;
			height: 30px;
			line-height: 30px;
			color: $color;
			text-align: center;
			border-radius: $br;
			.label {
				display: inline;
				font-size: 14px;
				font-weight: 600;
			}
		}

		.step-button:hover {
			background-color: lighten($color, 45%);
		}

		.next-button .label {
			margin-right: 5px;
		}
		.back-button .label {
			margin-left: 5px;
		}

		.page-number {
			display: inline-block;
			cursor: pointer;
			vertical-align: middle;
			margin: 0 3px;
		}
		.page-number {
			padding: 0 6px;
			height: 30px;
			border-radius: $br;
			background-color: lighten($color, 45%);
		}
		.page-number {
			line-height: 30px;
			color: $color;
			font-size: 14px;
			font-weight: 600;
		}
	}
}
