.react-slideouts-container {
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	width: 0;
	z-index: 1000;
}

.react-slideouts-container {
	.slideout-wrapper {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		background-color: var(--py-neutral-0);
		border-left: 1px solid var(--py-neutral-2);
	}
	.slideout-wrapper {
		animation-duration: 350ms;
		animation-fill-mode: both;
	}
	.slideout-wrapper.slide-enter {
		animation-name: slideInRight;
	}
	.slideout-wrapper.slide-leave {
		animation-name: slideOutRight;
	}

	.close-slideout-button {
		position: absolute;
		top: 20px;
		left: -25px;
		width: 40px;
		height: 40px;
		background-color: var(--py-neutral-0);
		border: 1px solid var(--py-neutral-2);
		@extend .circle;
	}
	.close-slideout-button {
		cursor: pointer;
		line-height: 40px;
		font-size: 21px;
		text-align: center;
		color: $color-red;
	}
	.close-slideout-button:hover {
		background-color: $color-red;
		color: var(--py-neutral-0);
		border: 1px solid darken($color-red, 15%);
	}

	.slideout-button-container {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: var(--py-neutral-0);
		z-index: 1;
	}
	.slideout-button-container {
		.button-content {
			margin: 20px 40px;
		}
		button.button.submit-feedback-button {
			width: 100%;
			height: 34px;
		}
	}
}

@keyframes slideInRight {
	from {
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideOutRight {
	from {
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(150%, 0, 0);
	}
}
