/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
$padding: 20px;

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.companyreporting-page {
	.page-inner-container {
		margin: 20px 20px 0 20px;

		.query-controls-container {
			position: absolute;
			top: 0;
			left: 0;
		}
		.query-builder-container {
			position: absolute;
			top: 0;
			left: 0;
		}
		.table-editor-container {
			position: absolute;
			top: 0;
			left: 0;
		}
		.save-builder-container {
			position: relative;
			top: 0;
			left: 0;
		}
		.minimized-container {
			position: absolute;
			top: 0;
			left: 0;
		}
		.pagination-container {
			margin-top: 20px;
		}
	}

	.breadcrumbs {
		margin-bottom: 20px;
	}

	.header {
		margin-left: 20px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
}

/*--------------------------------------------------------------
3.0 - Close Button
--------------------------------------------------------------*/
.companyreporting-page {
	.head-block {
		.close-button {
			position: absolute;
			cursor: pointer;
			top: 0;
			right: 0;
			height: 32px;
			width: 32px;
			color: $color-red;
			border: 1px solid var(--py-neutral-2);
			@extend .circle;
			.icon-el {
				position: absolute;
				top: 4px;
				left: 4px;
			}
		}

		.close-text {
			position: absolute;
			padding-right: 15px;
			top: 4px;
			right: 0px;
			width: 40px;
			line-height: 20px;
			text-align: right;
			font-size: 13px;
			font-weight: 600;
			opacity: 0;
			color: $color-red;
		}
		.close-text {
			transition: all 100ms;
		}

		.close-button:hover {
			background-color: lighten($color-red, 30%);
			color: $color-red;
			border: none;
		}
	}
}

/*--------------------------------------------------------------
4.0 - Minimized
--------------------------------------------------------------*/
.companyreporting-page {
	.minimized-container {
		box-sizing: border-box;
		cursor: pointer;
		width: 32px;
		height: 32px;
		border-radius: 2px;
		font-size: 0.9em;
		line-height: 32px;
		text-align: center;

		&:hover {
			color: var(--py-neutral-4);
		}
	}
}
