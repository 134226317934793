/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.page-view-group {
	.group-head {
		.group-name {
			font-size: 24px;
			line-height: 30px;
			color: var(--py-neutral-8);

			.name-container {
				display: inline-block;
				cursor: pointer;
				.cp-icon {
					margin-left: 10px;
					vertical-align: middle;
					display: inline-block;
					color: var(--py-neutral-5);
				}
				span {
					padding: 5px 10px;
					vertical-align: middle;
				}

				&:hover {
					span {
						background-color: var(--py-neutral-1);
					}
					.cp-icon {
						color: $color-blue;
					}
				}
			}
		}
	}

	.page-menu-navigation {
		position: absolute;
		top: 0;
		left: 0;

		.nav-buttons {
			padding: 10px 0;
			border-top: 1px solid var(--py-neutral-1);
		}

		.menu-item-wrapper {
			border-radius: 2px;
			.item {
				margin: 0 10px;
				line-height: 40px;
				cursor: pointer;
			}
			&:hover {
				background-color: var(--py-neutral-1);
			}
			&.is-active {
				background-color: $color-blue;
				color: var(--py-neutral-0);
			}
		}
	}

	.screen-wrapper {
		margin-top: 20px;
	}
}

/*--------------------------------------------------------------
3.0 - Back Button
--------------------------------------------------------------*/
.page-view-group {
	.back-to-groups-button {
		display: block;
		padding: 5px 8px;
		line-height: 30px;
		font-size: 14px;
		margin-bottom: 10px;

		.cp-icon {
			display: inline-block;
			margin-right: 8px;
			color: var(--py-neutral-2);
		}
		span {
			color: var(--py-neutral-5);
			font-weight: 600;
		}

		&:hover {
			background-color: var(--py-neutral-1);
			.cp-icon {
				color: $color-blue;
			}
			span {
				color: var(--py-neutral-8);
			}
		}
	}
}

/*--------------------------------------------------------------
4.0 - Delete Button
--------------------------------------------------------------*/
.page-view-group {
	.delete-group-wrapper {
		padding-top: 10px;
		border-top: 1px solid var(--py-neutral-1);
	}
}
/*--------------------------------------------------------------
5.0 - Add Members Curtain
--------------------------------------------------------------*/
.group-add-members-curtain {
	margin: 0 auto;
	padding: 40px 40px;
	width: 360px;
	background-color: var(--py-neutral-0);
	border-radius: 3px;

	.button-container {
		margin-top: 20px;
		padding-top: 20px;
		text-align: center;
		border-top: 1px solid var(--py-neutral-1);
		.button {
			width: 100%;
		}
	}
}

/*--------------------------------------------------------------
6.0 - Add Members Curtain
--------------------------------------------------------------*/
.page-view-group {
	.permissions-screen {
		.permission-item {
			padding: 8px 8px;
			border-bottom: 1px solid var(--py-neutral-1);

			&:first-child {
				border-top: 1px solid var(--py-neutral-1);
			}

			.component-input-checkbox {
				float: left;
			}

			.permission-label {
				margin-left: 30px;
				line-height: 20px;
				font-size: 15px;
				color: var(--py-neutral-8);
			}
		}
	}
}
