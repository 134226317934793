/*--------------------------------------------------------------
Base
--------------------------------------------------------------*/
.feedback-slideout {
	background-color: var(--py-neutral-0);
}

.feedback-slideout {
	.slideout-title {
		padding-top: 20px;
		font-size: 30px;
		line-height: 40px;
		font-weight: 900;
		color: $color-blue;
		text-transform: uppercase;
		text-align: center;
	}

	.change-subject {
		cursor: pointer;
		display: inline-block;
		font-size: 12px;
		color: var(--py-neutral-5);
	}
	.change-subject:hover {
		text-decoration: underline;
		color: var(--py-neutral-8);
	}

	.label {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		color: var(--py-neutral-8);
	}
	.hashtag-helper {
		position: absolute;
		top: 0;
		right: 0;
		line-height: 20px;
		font-size: 12px;
		color: var(--py-neutral-5);
	}

	.feedback-wrapper {
		margin: 0 40px;
	}
	.input-container {
		margin-top: 20px;

		.employee-picker {
			.people-chips {
				width: 420px;
			}
		}

		.add-myself-button {
			position: absolute;
			top: 0px;
			right: 0px;
		}
	}
}

/*--------------------------------------------------------------
Request Feedback Container
--------------------------------------------------------------*/
.request-feedback-message-container {
	margin-top: 10px;

	.request-message {
		padding: 8px 8px;
		background-color: var(--py-neutral-1);
		color: var(--py-neutral-9);
		border-radius: 4px;
	}

	.requestor-avatar {
		display: inline-block;
		vertical-align: top;
	}
}

/*--------------------------------------------------------------
Message
--------------------------------------------------------------*/
.feedback-slideout {
	.feedback-message {
		.editor {
			padding: 10px 10px;
			cursor: text;
			border-radius: 3px;
			background-color: var(--py-neutral-0);
			border: 1px solid var(--py-neutral-2);
		}
		.editor {
			font-size: 16px;
			line-height: 20px;
			color: var(--py-neutral-8);
		}
		.public-DraftEditor-content {
			min-height: 300px;
			font-size: 15px;
			line-height: 20px;
		}
	}
}

/*--------------------------------------------------------------
Selected Users
--------------------------------------------------------------*/
.feedback-slideout {
	.selected-users-list {
		margin-top: 10px;
		.selected-user {
			display: inline-block;
			padding: 5px 5px;
			.inner-content {
				padding: 5px 30px 5px 5px;
				background-color: var(--py-neutral-1);
				border: 1px solid var(--py-neutral-2);
				border-radius: 4px;
			}

			.remove {
				position: absolute;
				cursor: pointer;
				top: 5px;
				right: 5px;
				width: 20px;
				height: 22px;
				font-size: 13px;
				line-height: 22px;
				text-align: center;
				color: var(--py-neutral-3);
			}
			.remove:hover {
				color: var(--py-neutral-4);
			}

			.user-avatar {
				float: left;
				width: 22px;
				height: 22px;
			}
			.label {
				margin-left: 26px;
				line-height: 22px;
				font-size: 13px;
				color: var(--py-neutral-8);
				font-weight: 600;
			}
		}

		.selected-user:first-child {
			margin-top: 0;
		}
	}
}

/*--------------------------------------------------------------
Share Feedback
--------------------------------------------------------------*/
.feedback-slideout {
	.share-feedback {
		.feedback-option {
			padding: 0 5px;
			height: 30px;
			line-height: 30px;
			font-size: 14px;
			cursor: pointer;
			border-radius: 3px;
			.select-icon {
				position: absolute;
				top: 0;
				left: 0;
				width: 20px;
				height: 30px;
				line-height: 30px;
				text-align: center;
				color: var(--py-neutral-2);
			}
			.select-label {
				margin-left: 22px;
				color: var(--py-neutral-8);
			}
		}

		.feedback-option.is-inactive {
			opacity: 0.3;
		}

		.feedback-option:hover .select-icon {
			color: var(--py-neutral-3);
		}
		.feedback-option:hover .select-label {
			color: $color-blue;
		}
		.feedback-option.is-active {
			background-color: var(--py-neutral-1);
		}
		.feedback-option.is-active .select-icon {
			color: $color-green;
		}
	}
}

/*--------------------------------------------------------------
Submit Button
--------------------------------------------------------------*/
.feedback-slideout {
	.button-container {
		display: none;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: var(--py-neutral-0);
	}
	.button-container .button-content {
		margin: 20px 40px;
	}
	button.button.submit-feedback-button {
		width: 100%;
		height: 34px;
	}
}

/*--------------------------------------------------------------
Me Button
--------------------------------------------------------------*/
.feedback-slideout {
	$avatar-size: 18px;
	$button-height: 24px;
	$padding: ($button-height - $avatar-size) * 0.5;

	.me-button {
		position: absolute;
		padding: 0 8px;
		bottom: 3px;
		right: 3px;
		top: 23px;
		height: $button-height;
		background-color: $color-blue;
		border-radius: 2px;
	}
	.me-button {
		cursor: pointer;
		color: var(--py-neutral-0);
		border: 1px solid darken($color-blue, 10%);
		letter-spacing: 0.3px;
	}
	.me-button {
		box-shadow:
			rgba(0, 0, 0, 0.12) 0px 1px 6px,
			rgba(0, 0, 0, 0.12) 0px 1px 4px;
	}
	.me-button {
		.user-avatar {
			position: absolute;
			top: $padding;
			left: 0;
			width: $avatar-size - 2px;
			height: $avatar-size - 2px;
			@extend .circle;
			border: 1px solid var(--py-neutral-0);
		}
		.lbl {
			margin-left: $avatar-size + 5px;
			font-size: 12px;
			text-transform: uppercase;
			line-height: $button-height;
		}
	}

	.me-button:hover {
		background-color: lighten($color-blue, 8%);
	}
}

/*--------------------------------------------------------------
Sharing Definition
--------------------------------------------------------------*/
.feedback-slideout {
	.sharing-definition {
		font-size: 13px;
		font-weight: 600;
		color: $color-red;
	}
}
