/*--------------------------------------------------------------
2.0 - Other
--------------------------------------------------------------*/
.cp-table {
	.public_fixedDataTable_main {
		border-radius: 3px;
		outline: none;
	}
	.public_Scrollbar_main.public_Scrollbar_mainActive,
	.public_Scrollbar_main {
		border-left: none;
	}

	.public_fixedDataTable_horizontalScrollbar > div {
		top: 0px !important;
	}

	.header-cell {
		font-weight: 600;
		font-size: 13px;
		color: var(--py-neutral-5);
		text-transform: uppercase;
		background-color: var(--py-neutral-0);
	}
	.body-cell {
		font-size: 14px;
		color: var(--py-neutral-8);
		white-space: nowrap;
	}

	.body-cell .person-avatar {
		position: absolute;
		left: 0;
		@extend .circle;
	}
}
