/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.cp-icon {
	display: block;

	.awesome-icon {
		display: block;
		text-align: center;
	}
	.material-icons {
		display: block;
	}
	// svg { display: inherit; }
}
