/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
$padding: 20px;

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.page-slideout-feedback {
	padding-top: 50px;
	background-color: var(--py-neutral-1);
	a:hover {
		text-decoration: underline;
	}

	.slideout-container {
		margin: 0 auto;
		width: 600px;
		background-color: var(--py-neutral-0);
		border: 1px solid var(--py-neutral-2);
		border-radius: 3px;
		.wrapper {
			margin: 0 $padding;
			padding: $padding;
		}
		.close-slideout-button {
			display: none;
		}
	}

	.slideout-container {
		.button-container {
			display: none;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			background-color: var(--py-neutral-0);
		}
		.button-container .button-content {
			margin: 20px 40px;
		}
		button.button.submit-feedback-button {
			width: 100%;
			height: 34px;
		}
	}
}

/*--------------------------------------------------------------
3.0 - Mobile
--------------------------------------------------------------*/
@media only screen and (max-width: 750px) {
}
