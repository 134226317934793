/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
$padding: 16px;

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.page-view-feedback {
	padding: 50px 0px;
	background-color: var(--py-neutral-1);
	a:hover {
		text-decoration: underline;
	}

	.feedback-container {
		margin: 0 auto;
		width: 512px;
		background-color: var(--py-neutral-0);
		border: 1px solid var(--py-neutral-2);
		border-radius: 4px;
		.wrapper {
			padding: $padding;
		}
	}

	.controls-container {
		margin: 0 auto 20px auto;
		width: 512px;
	}
}

/*--------------------------------------------------------------
3.0 - Mobile
--------------------------------------------------------------*/
@media only screen and (max-width: 750px) {
	.page-view-feedback {
		.feedback-container {
			margin: 0 10px;
			width: auto;
		}
		.controls-container {
			margin: 0 10px 10px 10px;
			width: auto;
		}
	}
}
