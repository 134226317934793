/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.cp-datepicker {
}

/*--------------------------------------------------------------
3.0 - Date Input
--------------------------------------------------------------*/
.cp-datepicker {
	.date-input {
		box-sizing: border-box;
		padding: 0 5px;
		width: 100%;
		font-size: 14px;
		color: var(--py-neutral-8);
		border-radius: 2px;
		border: 1px solid var(--py-neutral-2);
	}
}

/*--------------------------------------------------------------
4.0 - Calendar
--------------------------------------------------------------*/
.cp-datepicker {
	.calendar {
		position: absolute;
		padding: 10px;
		top: 5px;
		left: 0;
		background-color: var(--py-neutral-0);
		border: 1px solid var(--py-neutral-2);

		.days-of-week {
			height: 20px;
			.day {
				position: absolute;
				top: 0;
				left: 0;
				height: 20px;
				line-height: 20px;
				text-align: center;
				font-size: 10px;
				font-weight: 400;
				color: var(--py-neutral-8);
			}
		}

		.current-month {
			margin: 0 10px 10px 10px;
			height: 20px;
			text-align: center;
			line-height: 20px;
			.month {
				display: inline-block;
			}
		}

		.change-month-button {
			position: absolute;
			top: 0;
			color: $color-blue;
			cursor: pointer;
		}
		.change-month-button.previous {
			left: 0;
		}
		.change-month-button.next {
			right: 0;
		}

		.change-month-button:hover {
			color: darken($color-blue, 15%);
		}

		.year-input-container {
			display: inline-block;
			margin-left: 5px;
			width: 60px;
		}

		.year-input {
			height: 20px;
			text-align: center;
			border: 2px solid var(--py-neutral-2);
		}
	}

	.calendar {
		.calendar-days {
			.day {
				position: absolute;
				text-align: center;
				color: var(--py-neutral-8);
				font-weight: 600;
				font-size: 13px;
				cursor: pointer;

				.inner-box {
					position: absolute;
					box-sizing: border-box;
					background-color: var(--py-neutral-1);
					border: 1px solid var(--py-neutral-1);
					line-height: 18px;
					font-size: 12px;
					font-weight: 600;
					color: var(--py-neutral-6);
				}
			}

			.day:hover .inner-box {
				background-color: var(--py-neutral-1);
				border: 1px solid var(--py-neutral-3);
				color: $color-blue;
			}
			.day.is-active .inner-box {
				background-color: $color-blue;
				border: 1px solid darken($color-blue, 20%);
				color: var(--py-neutral-0);
			}

			.day.non-active {
				display: none;
			}
		}
	}
}

/*--------------------------------------------------------------
5.0 - Time Picker
--------------------------------------------------------------*/
.cp-datepicker {
	.time-picker-container {
		text-align: center;

		.time-picker-button {
			cursor: pointer;
			height: 24px;
			width: 24px;
			line-height: 24px;
			text-align: center;
			color: var(--py-neutral-6);
			border: 1px solid var(--py-neutral-0);
			border-radius: 3px;
			&:hover {
				background-color: var(--py-neutral-1);
				border: 1px solid var(--py-neutral-2);
				color: $color-blue;
			}
		}

		.time-options {
			position: absolute;
			bottom: 30px;
			left: 50%;
			margin-left: -71px;
			width: 140px;
			border: 1px solid var(--py-neutral-2);
			background-color: var(--py-neutral-0);
			.time-item {
				padding: 0 5px;
				cursor: pointer;
				font-size: 13px;
				line-height: 30px;
				text-align: left;
			}
			.time-item:hover {
				background-color: var(--py-neutral-1);
				font-weight: 600;
			}
		}
	}
}
