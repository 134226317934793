/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/
$padding: 20px;

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.create-review-cycle-container {
	// .form-border { border-radius: 3px; }
	// .form-border { border: 1px solid lighten($color-blue, 20%); }
	// .form-border { border: 1px solid var(--py-neutral-1); }
	.form-border {
		border-top: 1px solid var(--py-neutral-1);
	}

	.next-controls-container-wrapper {
		position: fixed;
		bottom: 0;
		width: calc(100% - 80px);
		background-color: var(--py-neutral-0);
		display: flex;
		justify-content: center;

		.next-controls-container {
			width: calc(100% - 80px);
			max-width: 1328px;
			padding: 8px;
			margin: 0 auto;
			background-color: var(--py-neutral-2);
			border-radius: 4px;
			transition: bottom 150ms ease-out;
			display: flex;

			.next-button {
				margin-left: auto;
				margin-right: 0;
				padding: 8px 16px;
			}

			.back-button {
				margin-right: auto;
				margin-left: 0;
				padding: 8px 16px;
			}
		}
	}

	.action-buttons-container {
		height: 34px;
		button.newpy.button.back-button {
			position: absolute;
			margin: 0;
			top: 0;
			left: 0;
		}
		button.newpy.button.create-button {
			position: absolute;
			margin: 0;
			width: 150px;
			top: 0;
			right: 0;
		}
	}

	.page-title {
		margin-bottom: 10px;
		font-size: 20px;
		line-height: 30px;
		font-weight: 700;
		color: var(--py-neutral-6);
		text-align: center;
		text-transform: uppercase;
	}

	// button.newpy.create-button.button { height: 40px; width: 200px; font-weight: 400; font-size: 15px; }
}
