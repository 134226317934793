/*--------------------------------------------------------------
1.0 - Variables
--------------------------------------------------------------*/

/*--------------------------------------------------------------
2.0 - Base
--------------------------------------------------------------*/
.page-curtain-portal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	overflow-y: scroll;

	&.black {
		background-color: rgba(0, 0, 0, 0.75);
	}
	&.white {
		background-color: rgba(255, 255, 255, 0.9);
	}

	.portal-content {
		margin: 30px 20px 0 20px;
		padding-bottom: 50px;
	}

	.close-curtain {
		position: absolute;
		cursor: pointer;
		top: 20px;
		right: 20px;
		color: var(--py-neutral-4);
		&:hover {
			color: var(--py-neutral-6);
		}
	}
}
